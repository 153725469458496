import { HttpClientService, TranslationService } from '@amaris/lib-highway/services';
import { Injectable } from '@angular/core';
import {
  Observable, catchError, filter, map, of,
} from 'rxjs';
import { HttpOptionsModel } from '@amaris/lib-highway/models';
import { ToastrService } from 'ngx-toastr';
import { ApiResponse } from '../../../models/api-response.model';
import { MediacalPractice } from '../../../models/medical-practice.model';
import { environment } from '../../../../environments/environment';
import { ApiEndpoints } from '../../../enums/api-endpoints.enum';
import { DataStoreService } from '../../data-store/data-store.service';
import { Dropdown } from '../../../models/dropdown.model';
import { Invitation } from '../../../models/invitation.model';

@Injectable({
  providedIn: 'root',
})
export class MedicalPracticesService {
  constructor(
    private readonly httpClientService: HttpClientService,
    private readonly dataStoreService: DataStoreService,
    private readonly toastrService: ToastrService,
    private readonly translationService: TranslationService,
  ) { }

  getAll(): Observable<MediacalPractice[]> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.MedicalPractices}`,
      withCredentials: true,
    };
    return this.httpClientService.get<ApiResponse<MediacalPractice[]>>(options).pipe(
      map((response: ApiResponse<MediacalPractice[]>) => {
        this.dataStoreService.setMedicalPractices(response.data);
        this.dataStoreService.setMedicalPracticesDropdown(response.data.map((practice) => new Dropdown(practice.companyName, practice.id)));
        return response.data;
      }),
    );
  }

  getById(id: string): Observable<MediacalPractice> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.MedicalPractices}/${id}`,
      withCredentials: true,
    };
    return this.httpClientService.get<ApiResponse<MediacalPractice>>(options).pipe(
      map((response: ApiResponse<MediacalPractice>) => {
        this.dataStoreService.setCurrentMedicalPractice(response.data);
        return response.data;
      }),
    );
  }

  createMedicalPractice(medicalPractice: MediacalPractice): Observable<string> {
    const options: HttpOptionsModel = {
      url: environment.apiUrl + ApiEndpoints.MedicalPractices,
      body: medicalPractice,
      withCredentials: true,
    };
    return this.httpClientService.post<ApiResponse<string>>(options).pipe(
      map((response) => {
        if (response.success) {
          this.getById(response.data).subscribe();
          this.getAll().subscribe();
          this.toastrService.success('Cabinet créé avec succès');
        } else {
          this.toastrService.error('Une erreur est survenue lors de la création du cabinet');
        }
        return response.data;
      }),
      catchError(() => {
        this.toastrService.error('Une erreur est survenue lors de la création du cabinet');
        return of(null);
      }),
    );
  }

  updateMedicalPractice(medicalPractice: MediacalPractice): Observable<string> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.MedicalPractices}/${medicalPractice.id}`,
      body: medicalPractice,
      withCredentials: true,
    };
    return this.httpClientService.patch<ApiResponse<string>>(options).pipe(
      map((response) => {
        if (response.success) {
          this.getById(response.data).subscribe();
          this.getAll().subscribe();
          this.toastrService.success('Cabinet modifié avec succès');
        } else {
          this.toastrService.error('Une erreur est survenue lors de l\'édition du cabinet');
        }
        return response.data;
      }),
      catchError((error: any) => {
        this.toastrService.error('Une erreur est survenue lors de l\'édition du cabinet');
        return of(null);
      }),
    );
  }

  invitationToMedicalPractice(medicalPracticeId: string, email: string): Observable<string> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.MedicalPractices}/${medicalPracticeId}/invite`,
      body: { email },
      withCredentials: true,
    };
    return this.httpClientService.post<ApiResponse<string>>(options).pipe(
      map((response) => {
        if (response.success) {
          this.toastrService.success('Invitation envoyée avec succès');
        } else {
          this.toastrService.error('Une erreur est survenue lors de l\'envoi de l\'invitation');
        }
        return response.data;
      }),
      catchError(() => {
        this.toastrService.error('Une erreur est survenue lors de l\'envoi de l\'invitation');
        return of(null);
      }),
    );
  }

  acceptInvitationToMedicalPractice(medicalPracticeId: string): Observable<string> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.MedicalPractices}/accept/${medicalPracticeId}`,
      withCredentials: true,
    };
    return this.httpClientService.patch<ApiResponse<string>>(options).pipe(
      map((response) => {
        if (response.success) {
          this.toastrService.success('Invitation acceptée avec succès');
        } else {
          this.toastrService.error('Une erreur est survenue lors de l\'acceptation de l\'invitation');
        }
        return response.data;
      }),
      catchError(() => {
        this.toastrService.error('Une erreur est survenue lors de l\'acceptation de l\'invitation');
        return of(null);
      }),
    );
  }

  getMedicalPracticeInvitation(): Observable<Invitation[]> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.Users}/invitations`,
      withCredentials: true,
    };

    return this.httpClientService.get<ApiResponse<Invitation[]>>(options).pipe(
      map((response) => {
        if (response) {
          return response.data;
        // eslint-disable-next-line no-else-return
        } else {
          return null;
        }
      }),
      filter((response) => response !== null),
      catchError((error) => {
        if (error) {
          this.toastrService.error('An error occurred while retrieving the invitation');
        }
        return of(null);
      }),
    );
  }

  cancelInivitationToMedicalPractice(medicalPracticeId: string, invitationId : string, email : string): Observable<string> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.MedicalPractices}/${medicalPracticeId}/cancel/${invitationId}`,
      body: { email },
      withCredentials: true,
    };
    return this.httpClientService.delete<ApiResponse<string>>(options).pipe(
      map((response) => {
        if (response) {
          this.toastrService.success('Invitation annulée avec succès');
        } else {
          this.toastrService.error('Une erreur est survenue lors de l\'annulation de l\'invitation');
        }
        return response.data;
      }),
      catchError(() => {
        this.toastrService.error('Une erreur est survenue lors de l\'annulation de l\'invitation');
        return of(null);
      }),
    );
  }

  public getInvitedUsers(medicalPracticeId?: string): Observable<ApiResponse<Invitation[]>> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.MedicalPractices}/${medicalPracticeId}/guests`,
      withCredentials: true,
    };

    return this.httpClientService.get<ApiResponse<Invitation[]>>(options).pipe(
      map((response: any) => {
        if (response) {
          return response.data;
        }
        throw Error('une erreur est survenue lors de la récupération des invitations');
      }),
    );
  }

  revokeInvitationToMedicalPractice(medicalPracticeId: string, userId: string): Observable<string> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.MedicalPractices}/${medicalPracticeId}/revoke/${userId}`,
      withCredentials: true,
    };
    return this.httpClientService.patch<ApiResponse<string>>(options).pipe(
      map((response) => {
        if (response.success) {
          this.toastrService.success('Invitation révoquée avec succès');
        } else {
          this.toastrService.error('Une erreur est survenue lors de la révocation de l\'invitation');
        }
        return response.data;
      }),
      catchError(() => {
        this.toastrService.error('Une erreur est survenue lors de la révocation de l\'invitation');
        return of(null);
      }),
    );
  }

  cancelRevokeInvitationToMedicalPractice(medicalPracticeId: string, userId: string): Observable<string> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.MedicalPractices}/${medicalPracticeId}/cancel/${userId}`,
      withCredentials: true,
    };
    return this.httpClientService.patch<ApiResponse<string>>(options).pipe(
      map((response) => {
        if (response.success) {
          this.toastrService.success('Annulation de la révocation de l\'invitation effectuée avec succès');
        } else {
          this.toastrService.error('Une erreur est survenue lors de l\'annulation de la révocation de l\'invitation');
        }
        return response.data;
      }),
      catchError(() => {
        this.toastrService.error('Une erreur est survenue lors de l\'annulation de la révocation de l\'invitation');
        return of(null);
      }),
    );
  }

  invitationrenewalToMedicalPractice(medicalPracticeId: string, userId: string): Observable<string> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.MedicalPractices}/${medicalPracticeId}/renew/${userId}`,
      body: {},
      withCredentials: true,
    };
    return this.httpClientService.patch<ApiResponse<string>>(options).pipe(
      map((response) => {
        if (response.success) {
          this.toastrService.success('Invitation renouvelée avec succès');
        } else {
          this.toastrService.error('Une erreur est survenue lors du renouvellement de l\'invitation');
        }
        return response.data;
      }),
      catchError(() => {
        this.toastrService.error('Une erreur est survenue lors du renouvellement de l\'invitation');
        return of(null);
      }),
    );
  }

  verifyAccess(): Observable<any> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.Users}/verifyAccess`,
      withCredentials: true,
    };
    return this.httpClientService.get<ApiResponse<any>>(options).pipe(
      map((response: ApiResponse<any>) => {
        if (response) {
          return response;
        }
        throw new Error('Invalid response from server');
      }),
      ((error) => error),
    );
  }
}
